<template>
  <div class="form-group">
    <div class="form-row m-0">
      <FormInput
        class="col-12"
        type="text"
        :label="english? 'Address (English)' : 'ที่อยู่'"
        :placeholder="english? 'Address Line 1' : 'เลขที่ ซอย ถนน'"
        @change="emit_input()"
        required
        v-model.trim="addr1" />
      <FormInput
        class="col-12"
        type="text"
        :placeholder="english? 'Address Line 2' : 'แขวง เขต / ตำบล อำเภอ'"
        @change="emit_input()"
        required
        v-model.trim="addr2" />
      <FormInput
        class="col-4 mb-0"
        :placeholder="english? 'City' : 'เมือง'"
        @change="emit_input()"
        type="text"
        v-model.trim="city" />
      <FormSelect
        class="col-5 mb-0"
        short-dropdown
        :placeholder="english? 'Province' : 'จังหวัด'"
        :clearable="false"
        :options="province_list"
        @change="emit_input()"
        required
        v-model="province" />
      <FormInput
        class="col mb-0"
        :placeholder="english? 'Zip' : 'รหัสไปรษณีย์'"
        type="number"
        maxlength="5"
        required
        @change="emit_input()"
        v-model.trim="zip" />
    </div>
  </div>
</template>

<script>
import { PROVINCES, PROVINCES_ENG } from '@/const';

export default {
  name: 'form-address-input',
  props: {
    english: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    invalid () {
      return !this.addr1 || !this.addr2 || !this.province || !this.zip
    },
    address () {
      if (this.invalid) return null
      if (this.city) {
        return `${this.addr1}\n${this.addr2}\n${this.city} ${this.province} ${this.zip}`
      }
      return `${this.addr1}\n${this.addr2}\n${this.province} ${this.zip}`
    },
    province_list () {
      return this.english? PROVINCES_ENG : PROVINCES;
    }
  },
  data () {
    return {
      addr1: '',
      addr2: '',
      city: '',
      province: '',
      zip: '',
    }
  },
  methods: {
    emit_input () {
      this.$emit('input', this.address)
    }
  }
}
</script>