<template>
  <div v-if="suggest_lists" class="form-row m-0">
    <template v-if="contactType === ContactType.PERSON">
      <FormSuggestInput
        :key="contactType"
        v-if="!english"
        class="col-2"
        label="คำนำหน้า"
        required
        :min-length="0"
        :list="suggest_lists.person_title"
        @change="emitInput()"
        v-model="title" />
      <FormInput
        :class="english? 'col-6' : 'col-5'"
        :label="english? 'First Name (English)' : 'ชื่อจริง'"
        type="text"
        required
        @change="emitInput()"
        v-model.trim="firstName" />
      <FormInput
        :class="english? 'col-6' : 'col-5'"
        :label="english? 'Last Name (English)' : 'นามสกุล'"
        type="text"
        required
        @change="emitInput()"
        v-model.trim="lastName" />
    </template>
    <template v-else-if="contactType === ContactType.ORG">
      <FormSuggestInput
        :key="contactType"
        v-if="!english"
        class="col-3"
        label="ประเภทองค์กร"
        required
        :min-length="0"
        :list="suggest_lists.org_type"
        @change="emitInput()"
        v-model="title" />
      <FormInput
        class="col"
        :label="english? 'Organization Name (English)' : 'ชื่อองค์กร'"
        type="text"
        required
        @change="emitInput()"
        v-model.trim="orgName" />
    </template>
  </div>
</template>

<script>
import { SUGGEST_LIST } from '@/graphql/suggest-list'
import { ContactType } from '@/enum'

export default {
  name: 'form-contact-name-input',
  props: {
    english: {
      type: Boolean,
      default: false
    },
    contactType: {
      type: String,
      required: true
    }
  },
  computed: {
    name () {
      if (!this.english) {
        if (this.contactType === ContactType.PERSON) {
          return `${this.title}${this.firstName} ${this.lastName}`
        } else if (this.contactType === ContactType.ORG) {
          return `${this.title} ${this.orgName}`
        }
      }
      if (this.contactType === ContactType.PERSON) {
        return `${this.firstName} ${this.lastName}`
      } else if (this.contactType === ContactType.ORG) {
        return this.orgName
      }
      return ''
    }
  },
  data () {
    return {
      title: '',
      firstName: '',
      lastName: '',
      orgName: '',
    }
  },
  methods: {
    emitInput () {
      this.$emit('input', this.name)
    }
  },
  apollo: {
    suggest_lists: {
      query: SUGGEST_LIST,
      variables () {
        return { fields: ['person_title', 'org_type'] }
      },
      update (data) {
        const processed = { };
        data.suggest_list.forEach(item => processed[item.field_name] = item.suggest_list)
        return processed;
      },
    },
  }
}
</script>