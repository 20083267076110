<template>
<div class="form-group">
  <label  v-if="formLabel"
          :class="labelClass">
    {{ formLabel }}
    <i  v-if="required && !disabled"
        class="fas fa-star-of-life" />
  </label>
  <vSelect
    class="custom"
    :class="[
      color,
      { 'filled' : filled },
      { 'dropdown-sm': shortDropdown }
    ]"
    v-bind="$attrs"
    v-on="listeners"
    :disabled="disabled">
    <template #no-options>
      <div class="py-1 text-muted">
        ไม่มีรายการที่จะแสดง...
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <i v-bind="attributes" class="fas fa-chevron-down"></i>
    </template>
    <template #option="option">
      <slot name="option" :option="option"></slot>
    </template>
  </vSelect>
  <ErrorBox v-if="invalid" :msg="errorMsg" />
</div>
</template>

<script>
export default {
  name: 'form-select',
  components: {
    vSelect: () => import(/* webpackChunkName: "group-form-select" */ 'vue-select')
  },
  inheritAttrs: false,
  props: {
    formLabel: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    shortDropdown: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: ev => this.$emit('input', ev),
      }
    }
  }
}
</script>

<style lang="scss">
.v-select.custom {
  border-radius: $border-radius;
  background: $input-bg;
  font-size: $input-btn-font-size;
  font-weight: bold;
  color: $default;
  border-width: 0px;
  width: 100%;
  transition: all 150ms ease-in-out;
  &.vs--open {
    border-width: 0px;
    box-shadow: $input-focus-box-shadow;
  }
  &.vs--disabled {
    * { cursor: default; }
    .vs__dropdown-toggle {
      background: $accent-light;
    }
    .vs__actions {
      display: none;
    }
  }
  .vs__dropdown-toggle {
    @include input-btn-padding;
    border-style: none;
  }
  input.vs__search,
  span.vs__selected,
  .vs__selected-options,
  .vs__actions {
    background: transparent;
    font-weight: bold;
    //min-height: 33px;
    color: $default;
    margin: 0;
    padding: 0;
  }
  .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span.vs__selected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 98%;
    }
  }
  input.vs__search::placeholder {
    color: $placeholder;
  }
  &:not(.vs--single) {
    span.vs__selected {
      padding: 0 .3em;
      margin-right: .3em;
      margin-bottom: .1em;
      margin-top: .1em;
      background: $primary;
      color: $light;
      border-width: 0;
      font-size: 1.3rem;
      min-height: 1.3em;
      button.vs__deselect {
        padding: .4em .1em;
        svg { fill: $placeholder; }
        &:hover svg { fill: $light; }
      }
    }
    &.vs--open input.vs__search {
      width: 100%;
    }
  }
  .vs__open-indicator {
    color: $placeholder;
    font-size: .8em;
  }
  .vs__clear {
    margin: 0;
    padding: 0 .4em;
    svg { fill: $placeholder; }
    &:focus, &:hover {
      outline: none;
      svg { fill: $red; }
    }
  }
  .vs__dropdown-menu {
    @include dropdown-menu;
    @include scrollbar-style;
    .vs__dropdown-option {
      @include dropdown-option;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .vs__dropdown-option--highlight {
      background: $accent;
    }
    .vs__dropdown-option--selected {
      color: $primary;
    }
  }
  &.dropdown-sm {
    .vs__dropdown-menu {
      max-height: 30rem !important;
    }
  }
  &.yellow {
    &.vs--open {
      box-shadow: 0 0 0 .2rem $yellow;
    }
    &:not(.vs--single) span.vs__selected {
      background: $yellow;
    }
    .vs__dropdown-menu .vs__dropdown-option--selected {
      color: $yellow;
    }
  }
  &.pink {
    &.vs--open {
      box-shadow: 0 0 0 .2rem $pink;
    }
    &:not(.vs--single) span.vs__selected {
      background: $pink;
    }
    .vs__dropdown-menu .vs__dropdown-option--selected {
      color: $pink;
    }
  }
  &.green {
    &.vs--open {
      box-shadow: 0 0 0 .2rem $green;
    }
    &:not(.vs--single) span.vs__selected {
      background: $green;
    }
    .vs__dropdown-menu .vs__dropdown-option--selected {
      color: $green;
    }
  }
  &.red {
    &.vs--open {
      box-shadow: 0 0 0 .2rem $red;
    }
    &:not(.vs--single) span.vs__selected {
      background: $red;
    }
    .vs__dropdown-menu .vs__dropdown-option--selected {
      color: $red;
    }
  }
  &.grey {
    &.vs--open {
      box-shadow: 0 0 0 .2rem $medium;
    }
    &:not(.vs--single) span.vs__selected {
      background: $medium;
    }
    .vs__dropdown-menu .vs__dropdown-option--selected {
      color: $medium;
    }
  }
  &.filled {
    span.vs__selected {
      color: $light;
    }
    .vs__clear {
      svg {
        fill: $accent-light;
      }
      &:focus, &:hover {
        outline: none;
        svg { fill: $accent-dark; }
      }
    }
    &.yellow,
    &.green,
    &.grey,
    &.red {
      .vs__open-indicator {
        color: $accent-light;
      }
    }
    &.yellow .vs__dropdown-toggle {
      background: $yellow;
    }
    &.green .vs__dropdown-toggle {
      background: $green;
    }
    &.red .vs__dropdown-toggle {
      background: $red;
    }
    &.grey .vs__dropdown-toggle {
      background: $medium;
    }
  }
}
</style>