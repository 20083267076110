import { render, staticRenderFns } from "./DownloadSlip.vue?vue&type=template&id=c536a5f4&scoped=true&"
import script from "./DownloadSlip.vue?vue&type=script&lang=js&"
export * from "./DownloadSlip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c536a5f4",
  null
  
)

export default component.exports